import * as React from 'react';
import { IAppShellEventRoutesProps } from './appshell.routes';
import { connect } from 'react-redux';
import type { States } from '@@services/services';
import { DynLabel, withI18nHelper } from '@inwink/i18n';
import type { EventCopilotContent as EventCopilotDef } from '@@event/features/eventcopilot/eventcopilot';
import { UrlServiceContext } from '@@components/urlstatecontext';
import { SlowFadeTransition } from '@@components/transitions';
import { getPredicate } from '@inwink/expressions';
import { displayContent } from '@@services/itemshelpers';
import { Entities } from '@inwink/entities/entities';

export interface IAppShellEventCopilotProps extends IAppShellEventRoutesProps {
    appMetaData?: States.IAppMetadataState;
    urlservice?: States.IAppUrlContext;
    i18nHelper?: Entities.i18nHelper;
}

interface IAppShellEventCopilotState {}

const EventCopilot = React.lazy(() => import("@@event/features/eventcopilot/eventcopilot"));

@withI18nHelper()
class AppShellEventCopilotComponent extends React.Component<IAppShellEventCopilotProps, IAppShellEventCopilotState> {
    copilotRef = React.createRef<EventCopilotDef>();

    constructor(props) {
        super(props);
        
        this.state = {
        };
    }

    close = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.props.metadataMenuActions.showAssistant(false);
    };

    flushHistory = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        if (this.copilotRef.current){
            this.copilotRef.current.flushHistory();
        }
    };

    componentDidUpdate(prevProps): void {
        if (prevProps.appMetaData?.showAssistant !== this.props.appMetaData?.showAssistant) {
            if (this.props.appMetaData?.showAssistant === true && !document.body.classList.contains("showassistant")){
                document.body.classList.add("showassistant");
            }
            if (this.props.appMetaData?.showAssistant !== true && document.body.classList.contains("showassistant")){
                document.body.classList.remove("showassistant");
            }
        }
    }

    itemClicked = () => {
        if (window.innerWidth < 1400) {
            this.props.metadataMenuActions.showAssistant(false);
        }
    };

    render() {
        if (__SERVERSIDE__ || this.props.appMetaData?.showAssistant !== true){
            return null;
        }

        return <>
            <SlowFadeTransition key="overlay" in={true}>
                <div
                    className="appshell-assistant-overlay"
                    onClick={this.close}>
                    <div className={"bg bloctheme " + (this.props.copilotConfiguration.theme || "")} />
                </div>
            </SlowFadeTransition>
            <SlowFadeTransition key="assistant" in={true}>
                <div className={"appshell-assistant bloctheme " + (this.props.copilotConfiguration.theme || "")}>
                    <header>
                        <DynLabel i18n={this.props.copilotConfiguration.title} className="title" component="h3" />
                        <div className="actions">
                            <button
                                onClick={this.flushHistory}
                                title={this.props.i18nHelper.translate("eventcopilot.clearhistory.title")}>
                                <i className="inwink-sync" /></button>
                            <button onClick={this.close}><i className="inwink-dialog-cancel" /></button>
                        </div>
                    </header>
                    <section>
                        <React.Suspense>
                            <UrlServiceContext.Consumer>
                                {(urlservice) => <EventCopilot
                                    ref={this.copilotRef}
                                    event={this.props.event}
                                    i18n={this.props.i18n}
                                    user={this.props.user}
                                    urlservice={urlservice}
                                    config={this.props.copilotConfiguration}
                                    onItemClicked={this.itemClicked}
                                />}
                            </UrlServiceContext.Consumer>
                            
                        </React.Suspense>
                    </section>
                </div>
            </SlowFadeTransition>
        </>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        appMetaData: state.appMetaData
    };
}


export const AppShellEventCopilot: new (any) => React.Component<IAppShellEventCopilotProps, any> = connect(
    mapStateToProps,
    null)(AppShellEventCopilotComponent as any) as any;


@withI18nHelper()
class AppShellEventCopilotTriggerComponent extends React.Component<IAppShellEventCopilotProps, IAppShellEventCopilotState> {
    show = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.props.metadataMenuActions.showAssistant(true);
    };

    render() {
        const copilotConf = this.props.copilotConfiguration;
        const allowCopilot = copilotConf?.enabled == true
            && copilotConf?.display?.floatingButton?.enabled == true
            && canShowEventCopilot(copilotConf, this.props);

        if (__SERVERSIDE__ || !allowCopilot || this.props.appMetaData?.showAssistant === true) {
            return null;
        }

        const displayConf = copilotConf?.display?.floatingButton;
        let content = <i className="inwink-z-chatbot2"/>;
        if (displayConf?.icon?.picture){
            content = <img
                src={displayConf?.icon?.picture}
                className={displayConf.icon.className}
                style={displayConf.icon.style}/>;
        } else if (displayConf?.icon?.className){
            content = <i
                className={displayConf.icon.className}
                style={displayConf.icon.style}/>;
        }

        return <SlowFadeTransition key="assistant" in={true}>
            <div
                className="assistant-trigger bloctheme clickable"
                title={this.props.i18nHelper.translateBag(this.props.copilotConfiguration.title)}
                onClick={this.show}
                style={displayConf?.style}>
                {content}
            </div>
        </SlowFadeTransition>;
    }
}

export const AppShellEventCopilotTrigger: new (any) => React.Component<IAppShellEventCopilotProps, any> = connect(
    mapStateToProps,
    null)(AppShellEventCopilotTriggerComponent as any) as any;

export function canShowEventCopilot(
    copilotConf: States.ICopilotConfiguration, 
    props: { event?: States.IEventState, user?: States.IAppUserState, i18n?: States.i18nState }
) {
    if (copilotConf?.display?.condition) {
        const condition = copilotConf.display.condition;
        if (!displayContent(props.user, 
            { ...condition, event: props.event, community: null })
        ) {
            return false;
        }

        if (condition.showIf && !condition.showIfPredicate) {
            condition.showIfPredicate = getPredicate(condition.showIf);
        }
        if (condition.showIfPredicate) {
            const res = condition.showIfPredicate({
                i18nstate: props.i18n,
                eventstate: props.event,
                userstate: props.user,
                event: props.event.detail,
                user: props.user?.currentUser?.detail
            });
            if (!res) {
                return false;
            }
        }
    }

    return true;
}    