import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import type { States } from "@@services/services";
import { metadataMenuActions } from '@@services/appmetadataactions/menu';

export interface IAppHeaderEventCopilotProps {
    appMetaData?: States.IAppMetadataState;
    metadataMenuActions?: typeof metadataMenuActions;
}

function AppHeaderEventCopilotComponent(props: IAppHeaderEventCopilotProps) {
    const openCopilot = () => {
        props.metadataMenuActions.showAssistant(!props.appMetaData.showAssistant);
    };

    return (
        <div className="appheader-action-button appheader-copilot">
            <AsyncButton className="button chat-button" id="chat-button" onClick={openCopilot}>
                <i className="icon inwink-z-chatbot2" />                
            </AsyncButton>
        </div>
    );
}

function mapStateToUserMessageProps(state: States.IAppState) {
    return {
        appMetaData: state.appMetaData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        metadataMenuActions: bindActionCreators(metadataMenuActions, dispatch)
    };
}

export const AppHeaderEventCopilot = connect(
    mapStateToUserMessageProps, mapDispatchToProps
)(AppHeaderEventCopilotComponent as any);