import * as React from 'react';
import { TransitionGroup } from "react-transition-group";
import type { Entities } from "@inwink/entities/entities";
import { SimpleFadeTransition } from "@@components/transitions";
import { FullNameBubble } from "@@components/personbubble/personbubble";
import { userMessageModule } from "@@routes/appmodules";
import { wrapReduxStore, IInwinkStore } from "@@store/index";
import { currentUserActions } from '@@event/services/useractions/currentuser';
import type { IAppHeaderEventProps } from "./index";

import './avatar.less';

interface IAppHeaderAvatarProps extends IAppHeaderEventProps {
    i18nHelper?: Entities.i18nHelper;
    history?: any;
    store?: IInwinkStore;
}

class AppHeaderAvatarComponent extends React.Component<IAppHeaderAvatarProps> {
    onClickAvatar = (arg: React.MouseEvent) => {
        const { user } = this.props;
        const currentUser = user?.currentUser;
        const isUserConnected = !!currentUser;
        if (isUserConnected) {
            const store = this.props.store;
            currentUserActions.showProfile(
                this.props.i18nHelper,
                this.props.history,
                arg.currentTarget as HTMLElement
            )(store.dispatch, store.getState);
            // history.push(urlservice.pageUrl('me'));
        } else {
            userMessageModule().then((mod) => {
                const store = this.props.store;
                mod.loginActions.showLogin()(store.dispatch, store.getState);
            });
        }
    };

    render() {
        const { user, event, i18nHelper } = this.props;
        const currentUser = user?.currentUser;
        const isUserConnected = !!currentUser;
        let title = '';
        let avatarNode;
        if (isUserConnected) {
            const userPicture = (currentUser?.detail as any)?.photo?.url;
            avatarNode = userPicture ? <FullNameBubble fullname="" picture={userPicture} /> : <i className="inwink-account" />;
            title = i18nHelper.translate('networking.menu.profile.infos');
        } else {
            avatarNode = <i className="inwink-login" />;
            title = i18nHelper.translate('menu.anonymous');
        }

        const enabledUserProfil = event?.detail?.configuration?.companion?.headerSettings?.enabledUserProfil;

        if (!enabledUserProfil) {
            return null;
        }

        return (
            <TransitionGroup component="div" className="avatar-content avatarevent-content">
                <SimpleFadeTransition key="avatar">
                    <button
                        type="button"
                        onClick={this.onClickAvatar}
                        title={title}
                    >
                        { avatarNode }
                    </button>

                </SimpleFadeTransition>
            </TransitionGroup>
        );
    }
}

export const AppHeaderAvatar = wrapReduxStore(AppHeaderAvatarComponent);
