import * as React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import type { IPopoverManager } from '@inwink/modals/popovermgr';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import type { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';
import { AppHeaderSearch } from './search';
import { AppHeaderAvatar } from "./avatar";
import type { IAppHeaderSideBarProps } from './appheader.sidebar.props';
import { AppHeaderEventCopilot } from './eventcopilot';
import { canShowEventCopilot } from '@@event/shell/appshell.copilot';

export interface IAppHeaderEventProps {
    visualConfiguration: Entities.IVisualConfigurationTemplate;
    copilotConfiguration: States.ICopilotConfiguration;
    headerTemplate: Entities.IContentTemplate;
    event?: States.IEventState;
    i18n?: States.i18nState;
    rootwebsite?: States.IRootWebsiteState;
    popovermgr?: IPopoverManager;
    i18nHelper?: Entities.i18nHelper;
    page?: States.ICurrentPageState;
    user?: States.IAppUserState;
    userChat?: States.IAppUserChatState;
    visualstate?: string[];
    location?: States.ILocation;
    match?: States.ILocationMatch;
}

export interface IAppHeaderEventState {

}

@withI18nHelper()
class AppHeaderEventComponent extends React.Component<IAppHeaderEventProps, IAppHeaderEventState> {
    LoggedUserComponent: () => any;

    Sidebar: React.ComponentClass<IAppHeaderSideBarProps, any>;

    loggedUserComponentLoading: Promise<any>;

    componentDidMount() {
        this.initLoggedUserComponent();
    }

    componentDidUpdate() {
        if (!this.loggedUserComponentLoading && this.props.user.currentUser?.detail) {
            this.initLoggedUserComponent();
        }
    }

    initLoggedUserComponent() {
        if (!this.LoggedUserComponent && !this.loggedUserComponentLoading && this.props.user?.currentUser?.detail) {
            this.loggedUserComponentLoading = getHeaderUserModule().then((mod) => {
                // eslint-disable-next-line react/display-name
                this.LoggedUserComponent = () => {
                    return <>
                        <mod.AppHeaderUserChat
                            visualstate={this.props.visualstate}
                            page={this.props.page}
                            rootwebsite={this.props.rootwebsite}
                            location={this.props.location}
                            userChat={this.props.userChat}
                            popovermgr={this.props.popovermgr}
                            i18nHelper={this.props.i18nHelper}
                            visualConfiguration={this.props.visualConfiguration}
                        />
                        <mod.AppHeaderNotifications {...this.props} />
                    </>;
                };

                this.Sidebar = mod.AppHeaderSideBar as any;
            });
        }
    }

    render() {
        let notifsModal;

        if (this.Sidebar) {
            notifsModal = React.createElement(this.Sidebar, {...this.props });
        }

        const copilotConf = this.props.copilotConfiguration;
        const allowCopilot = copilotConf?.enabled == true
            && copilotConf?.display?.appbar?.enabled == true
            && canShowEventCopilot(copilotConf, this.props);
        return <>
            <AppHeaderSearch {...this.props} />
            {this.LoggedUserComponent ? <this.LoggedUserComponent /> : null}
            {allowCopilot ? <AppHeaderEventCopilot /> : null}
            <AppHeaderAvatar {...this.props} />

            {notifsModal}
        </>;
    }
}

export function getHeaderUserModule() {
    return import(
        /* webpackChunkName: "st-header-user" */
        "@@event/components/appheader/appheader.usermodules"
    );
}

export function getHeaderNotificationItem() {
    return import(
        /* webpackChunkName: "mod-notif-item" */
        "@@event/components/appheader/notifcenter.itemslist.item"
    );
}

function mapStateToProps(state: States.IAppState) {
    return {
        i18n: state.i18n,
        user: state.user,
        event: state.event,
        rootwebsite: state.rootwebsite,
        pages: state.pages,
        // appMetaData: state.appMetaData,
        userChat: state.userChat,
        page: state.pages.currentPage
    };
}
function mapDispatchToProps() {
    return {
        // i18nActions: bindActionCreators(i18nActions, dispatch)
    };
}
export const AppHeaderEvent: new (props: IAppHeaderEventProps)
=> React.Component<IAppHeaderEventProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppHeaderEventComponent as any) as any;
