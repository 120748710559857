import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { States } from '@@services/services';
import { withUrlService } from '@@components/urlstatecontext';
import type { IAppHeaderEventProps } from "./index";

import './search.less';

export interface IAppHeaderSearchProps extends IAppHeaderEventProps {
    urlservice? : States.IAppUrlContext;
    history?: any;
}

@withUrlService()
class AppHeaderSearchComponent extends React.Component<IAppHeaderSearchProps, any> {
    inputRef = React.createRef<HTMLInputElement>();

    inputPanel = React.createRef<HTMLDivElement>();

    /*
    constructor(props: IAppHeaderSearchProps) {
        super(props);
        this.state = {
            showInput: false,
            initialized: false
        };
    }
    */

    goToSearch = (arg: React.MouseEvent<any>) => {
        arg?.preventDefault();
        const { history, urlservice } = this.props;
        const url: string = urlservice.pageUrl('/search');

        history.push(url);
    };

    render() {
        const eventconf = this.props.event && this.props.event.detail && this.props.event.detail.configuration;
        if (eventconf && eventconf.companion && eventconf.companion.globalSearch && eventconf.companion.globalSearch.enabled) {
            return <div className="appheader-action-button appheader-search">
                {/*
                <div className="appheader-search-input">
                    <div className="appheader-search-input-content" ref={this.inputPanel}>
                        <InWinkInput
                            ref={this.inputRef}
                            type="search"
                            onKeyPress={this.onKeyPress}
                            onBlur={this.onBlur}
                        />
                        <button type="button" className="triggersearchbtn" onMouseDown={this.startSearch}>
                            <i className="inwink-search" /></button>
                    </div>
                </div>
                */}
                <div className="appheader-search-action">
                    <div className="button searchbtn" onClick={this.goToSearch}>
                        <i className="inwink-search" />
                    </div>
                </div>
            </div>;
        }

        return null;
    }
}

export const AppHeaderSearch : new (any)
=> React.Component<IAppHeaderSearchProps, any> = withRouter(AppHeaderSearchComponent as any) as any;
